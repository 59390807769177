<template>
  <div class="home black--text">
  <v-row dense align="center" justify="center">
  <v-col cols="12" xs="8" sm="8">
 <v-img
  :src="require('../assets/logo.svg')"
  max-width="150"
  contain
  alt="טופס ייפוי כוח לקבלת מוצרי קנביס באמצעות שליח"
  ></v-img>
  </v-col>
  <v-col cols="12" xs="2" sm="2" style="text-align:left;">
    <v-hover>
   <a slot-scope="{ hover }"
  :style="`${hover? '': 'text-decoration:none;'}`" class="hvr-grow black--text" href="tel:*9844">9844* <v-icon small left color="black">mdi-phone</v-icon></a>
  </v-hover>
  </v-col>
  <v-col cols="12" xs="2" sm="2" style="text-align:left;">
    <v-hover class="pr-2">
  <a slot-scope="{ hover }"
  :style="`${hover? '': 'text-decoration:none;'}`" class="hvr-grow black--text" href="https://api.whatsapp.com/send/?phone=97237482000&text&app_absent=0">03-7482000 <v-icon small left color="black">mdi-whatsapp</v-icon></a>
  </v-hover>
  </v-col>
  </v-row>
  <v-divider class="my-2"/>
<v-row dense align="center" style="text-align:center;">
<v-col cols="12" class="my-2 text-h5 style1 font-weight-bold">
טופס ייפוי כוח לקבלת מוצרי קנביס באמצעות שליח
</v-col>
</v-row>
<v-divider class="my-5"/>
<v-row dense style="text-align:center;">
</v-row>
  <v-form
  ref="contactus"
  v-model="valid"
  lazy-validation
>
  <v-row>
  <v-col cols="12" xs="4" sm="4" >
    <v-text-field
    v-model="firstName"
    label="שם פרטי"
    :rules="requiredRules"
    required
    outlined
    tabindex="1"
  ></v-text-field>
  </v-col>
  <v-col cols="12" xs="4" sm="4">
   <v-text-field
    v-model="lastName"
    label="שם משפחה"
    :rules="requiredRules"
    required
    outlined
    tabindex="2"
  ></v-text-field>
  </v-col>
  <v-col cols="12" xs="4" sm="4">
    <v-text-field
    v-model="clientId"
    label="תעודת זהות"
    :rules="requiredRules"
    required
    outlined
    v-mask="'#########'"
    tabindex="3"
  ></v-text-field>
  </v-col>
  <v-col cols="12" xs="12" sm="12">
    <v-text-field
    v-model="Address"
    label="כתובת (יש לפרט הכתובת המופיעה על רישיון השימוש בקנביס)"
    :rules="requiredRules"
    type="address"
    required
    outlined
    tabindex="5"
  ></v-text-field>
  </v-col>
  <v-col cols="12" xs="5" sm="5">
     <v-select
     v-model="BrancheSelected"
     :items="Branches"
     item-text="title"
     item-value="id"
     return-object
     label="מיפה את כוחו של בית המרקחת (בחר בית מרקחת)"
      required
      outlined
      :rules="requiredRules"
      tabindex="6"
      clearable
     />
  </v-col>
  <v-col cols="12" xs="7" sm="7">
    להעביר אלי מוצרי קנביס באמצעות שליח מאושר על ידי היחידה לקנביס רפואי לבצע שינוע של קנביס או מוצריו.
  </v-col>
  <v-col cols="12" xs="12" sm="12">
    <v-text-field
        v-model="Dob"
        label="תאריך"
        :rules="requiredRules"
        required
        outlined
        @blur="date = parseDate(Dob)"
        v-mask="'##/##/####'"
        tabindex="6"
        disabled
      ></v-text-field>
  </v-col>
  <v-col cols="12" xs="12" sm="12">
     <h3 class="text-center">אנא חתום כאן</h3>
      <p class="text-center">נא לחתום ברור בתוך המסגרת האדומה</p>
        <div class="text-left mb-1">
      <v-btn x-small outlined color="secondary" @click="undo()">נקה חתימה</v-btn>
      </div>
    <VueSignaturePad key="mypad2" width="100%" height="220px" ref="signaturePad" style="border: 2px solid red; margin:0px auto;" />
  </v-col>
  </v-row>
  <v-divider v-show="showErr" class="my-2"/>
   <v-alert
    v-show="showErr"
    dense
    color="error"
    dark
    class="justify-center text-center white--text"
  >
    <ul class="errLogin">
      <template v-for="errText in errTexts">
        <li
          v-for="errElement in errText"
          :key="errElement"
        >
          {{ errElement }}
        </li>
      </template>
    </ul>
  </v-alert>
  <!-- <v-divider class="my-2"/> -->
  <v-row dense class="my-5">
  <v-hover>
  <v-btn
  slot-scope="{ hover }"
  :color="`${hover? '#E64A19': '#f9ab98'}`"
  :class="`${hover? 'text-decoration-underline white--text font-weight-bold': 'font-weight-bold style1'}`"
  block
  tile
  dark
  @click="Sendform"
  tabindex="11"
  >
  אישור ייפוי כוח
  </v-btn>
  </v-hover>
   </v-row>
     </v-form>
   <v-divider class="my-4"/>
   <v-row dense>
    <v-col cols="5">
    <v-row dense align="start" justify="center" class="text-start text-caption">
    <v-col cols="12">
   <h2 class="text-title style1">פרטים ליצירת קשר:</h2>
   </v-col>
   <v-col cols="12" class="text-body-2 mb-0 pb-0" tabindex="12">
   קבלת קהל בימים א' – ה' בין השעות 21:00 – 09:00
    <br/>
במוקד הטלפוני עד השעה 18:00, ובימי שישי קבלת קהל ומענה עד השעה 15:00
   </v-col>
    </v-row>
    </v-col>
    <v-col cols="2" style="text-align:center;">
     <v-divider vertical class="my-2"/>
    </v-col>
    <v-col cols="5">
     <v-row dense align="start" justify="center" class="text-start text-caption">
      <v-col cols="12">
   <h2 class="text-title style1">כתובות סניפי בית מרקחת גבעול:</h2>
   </v-col>
    <v-col cols="12" class="text-body-2 mt-0 pt-0">
   <template v-for="(Branche, index) in Branches">
    {{ Branche.address }}, {{ Branche.city }} <template v-if="index < Branches.length - 1">|</template>
    </template>
   </v-col>
     </v-row>
    </v-col>
   <!-- <v-col cols="12">
   * ההטבה כפופה למימוש כל 5 המרשמים הקודמים ברציפות ובמלואם ברשת בתי המרקחת גבעול / משלוח.
   </v-col> -->
   </v-row>
   <v-divider class="my-4"/>
   <v-row dense align="center" justify="center" class="text-center text-caption">
    <v-col cols="12" class="text-caption">
אין במידע זה תחליף להיוועצות עם רופא או רוקח בטרם רכישת תכשיר רפואי והתחלת הטיפול בו. <br/>
יש לעיין בעלון לצרכן לפני השימוש בתכשיר הרפואי. <br/>
מומלץ להתייעץ עם הרוקח בכל הנוגע למטרות ואופן השימוש, תופעות לוואי, אינטראקציה עם תכשירים רפואיים אחרים. <br/>
להתייעצות עם רוקח פנו למספר הטלפון 03-7482000 ובנוסף באמצעות דואר אלקטרוני בכתובת Info@givol-pharmacy.co.il
   </v-col>
   </v-row>
  </div>
</template>

<script>
import { bus } from './../main'
import { mask } from 'vue-the-mask'
// @ is an alias to /src
export default {
  directives: {
    mask
  },
  data () {
    return {
      BrancheSelected: '',
      Branches: [
        { id: 1, title: 'גבעול מרחוב הירקון 288, תל אביב', city: 'ת"א', address: 'הירקון 288', email: 'approved@givol-pharmacy.co.il', 'signName': 'יארא סלאמה', 'signGender': 'רוקחת אחראית', 'sign': 'yara.png' },
        { id: 2, title: 'גבעול מרחוב יפו 216, ירושלים', city: 'ירושלים', address: 'יפו 216', email: 'jerusalem@givol-pharmacy.co.il', 'signName': 'רווית אורן', 'signGender': 'רוקחת אחראית', 'sign': 'ravit.png' },
        { id: 3, title: 'גבעול מרחוב גיא אוני 12, אשדוד', city: 'אשדוד', address: 'גיא אוני 12', email: 'alaa.agamy@givol-pharmacy.co.il', 'signName': 'אבו עגמי עלא', 'signGender': 'רוקח אחראי', 'sign': 'abu.png' },
        { id: 4, title: 'גבעול מרחוב רבי שמעון בר יוחאי 6, דימונה', city: 'דימונה', address: 'רבי שמעון בר יוחאי 6', email: 'dimona@givol-pharmacy.co.il', 'signName': 'איברהים אל טורי', 'signGender': 'רוקח אחראי', 'sign': 'Ivrahim.png' },
        { id: 5, title: 'גבעול מרחוב אוסקר שינדלר 7, חיפה', city: 'חיפה', address: 'אוסקר שינדלר 7', email: 'haifa@givol-pharmacy.co.il', 'signName': 'אשרף חלאילה', 'signGender': 'רוקח אחראי', 'sign': '' },
        { id: 6, title: 'גבעול מרחוב גאולה 6, רעננה', city: 'רעננה', address: 'גאולה 6', email: 'doroncann@pharmacare.co.il', 'signName': 'נטליה רונן', 'signGender': 'רוקחת אחראית', 'sign': 'natalia.png' },
        { id: 7, title: 'גבעול מרחוב מונטיפיורי 1, באר שבע', city: 'באר שבע', address: 'מונטיפיורי 1', email: 'approved@givol-pharmacy.co.il', 'signName': 'רות קרוטמן', 'signGender': 'רוקחת אחראית', 'sign': 'ruth.png' },
        { id: 8, title: 'גבעול מרחוב חבצלת השרון 36, הרצליה', city: 'הרצליה', address: 'חבצלת השרון 36', email: 'Power@cannolam.com', 'signName': 'אמגד עזאם', 'signGender': 'רוקח אחראי', 'sign': 'amjad.png' },
        { id: 9, title: 'גבעול מרחוב תל חי 63, אשדוד', city: 'אשדוד', address: 'תל חי 63', email: 'Power@cannolam.com', 'signName': 'אירנה חוטובלי', 'signGender': 'רוקחת אחראית', 'sign': 'irena.png' },
        { id: 10, title: 'גבעול מרחוב הכרמל 2, יקנעם עילית', city: 'יקנעם עילית', address: 'הכרמל 2', email: 'approved@givol-pharmacy.co.il', 'signName': 'שרה אבו ניל', 'signGender': 'רוקחת אחראית', 'sign': 'sara.png' }
      ],
      Dob: window.moment().format('DD/MM/YYYY'),
      firstName: null,
      lastName: null,
      clientId: null,
      Address: null,
      Email: null,
      clientMobile: null,
      gender: null,
      marketing: false,
      roles: false,
      genders: ['זכר', 'נקבה'],
      date: null,
      dateFormatted: null,
      menu: false,
      valid: true,
      showErr: false,
      errTexts: [],
      breadcrumbs: [
        {
          text: 'לוח בקרה',
          disabled: false,
          to: { path: '/' }
        }
      ],
      requiredRules: [
        v => !!v || 'שדה חובה'
      ],
      usernameRules: [
        v => !!v || 'שדה חובה',
        v => /.+@.+\..+/.test(v) || 'יש להקליד דואר אלקטרוני תקין'
      ]
    }
  },
  created () {
    window.Store.commit('Loader', false)
    this.showErr = false
    this.errTexts = []
    // this.$refs.signaturePad.resizeCanvas()
    this.$nextTick(() => {
      this.Dob = window.moment().format('DD/MM/YYYY')
      this.date = this.parseDate(this.Dob)
    })
  },
  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      let vm = this
      setTimeout(function () {
        vm.Dob = window.moment().format('DD/MM/YYYY')
        vm.date = vm.parseDate(vm.Dob)
      }, 2000)
    },
    undo () {
      this.$refs.signaturePad.clearSignature()
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    Sendform () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      this.showErr = false
      this.Dob = window.moment().format('DD/MM/YYYY')
      this.errTexts = []
      let dataQ = {
        firstName: this.firstName,
        lastName: this.lastName,
        clientId: this.clientId,
        Address: this.Address,
        pharm: this.BrancheSelected,
        dates: this.Dob,
        signatureJSON: data
      }
      let vm = this
      if (!this.$refs.contactus.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.error--text:first-of-type > div > div:nth-child(1) > div > input')
          const elRadio = this.$el.querySelector('.error--text:first-of-type > div > div:nth-child(1) > div > div > div > input')
          const el2 = this.$el.querySelector('.error--text:first-of-type')
          // el.focus()
          if ((!!el && el.type === 'text') || (!!el && el.type === 'email') || (!!el && el.type === 'tel')) {
            el.focus()
          } else if (!!elRadio && elRadio.type === 'radio') {
            elRadio.focus()
          } else {
            el.focus()
          }
          this.$vuetify.goTo(el2)
        })
      }
      if (this.$refs.contactus.validate()) {
        if (!isEmpty) {
          window.Store.commit('Loader', true)
          window.axios.post(window.API.registerForm, dataQ)
            .then(response => {
              window.Store.commit('Loader', false)
              bus.$emit('Open_snackbar', 'success', 'נשלח בהצלחה!', '1')
              vm.$refs.contactus.reset()
              vm.$refs.signaturePad.clearSignature()
              vm.resetData()
            })
            .catch(error => {
              window.Store.commit('Loader', false)
              vm.showErr = true
              let errorMessage = error.response.data
              vm.errTexts = errorMessage.err
              bus.$emit('Open_snackbar', 'error', errorMessage.err)
            })
        } else {
          bus.$emit('Open_snackbar', 'error', 'התגלתה שגיאה, יש לחתום בתחתית הטופס', '1')
        }
      } else {
        bus.$emit('Open_snackbar', 'error', 'התגלתה שגיאה, אנא בדוק את הטופס', '1')
      }
    },
    onFocus () {
      this.$refs.autocomplete.isMenuActive = true
    }
  },
  mounted () {
    // let autocompleteInput = this.$refs.autocomplete.$refs.input
    // autocompleteInput.addEventListener('focus', this.onFocus, true)
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }
  }
}
</script>

<style>
.theme--light.v-messages {
  color: #000000!important;
}
.theme--light.v-label {
  color: #000000!important;
}
.style1 {
  color: #004337!important;
}
.style2 {
  color: #004337!important;
}
</style>
