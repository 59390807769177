import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import { bus } from './main'

Vue.use(Vuex)

export const Store = new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    tokens: null,
    currentUser: {
      name: null,
      email: null,
      currentLangDirection: true,
      currentLang: 'he',
      studioUrl: 'BuNp9z033Qirx7XeAxbg',
      multiBranch: true,
      studioName: 'Beesoft',
      studiosCount: 0,
      studiosNames: [],
      id: null
    },
    sourceFull: {
      source: null,
      source2: null,
      source3: null,
      type: null,
      id: null
    },
    lang: 'he',
    overlayLoading: false,
    colors: ['#F44336', '#B71C1C', '#E91E63', '#880E4F', '#9C27B0', '#4A148C', '#673AB7', '#3F51B5', '#2962FF', '#0D47A1', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#1B5E20', '#8BC34A', '#CDDC39', '#827717', '#FFEB3B', '#FFC107', '#FF9800', '#E65100', '#FF5722', '#BF360C', '#795548', '#3E2723', '#607D8B', '#263238', '#9E9E9E', '#000000'],
    swatchStyle: { width: '30px', height: '30px' },
    docs: [],
    hokFaildReason: null
  },

  actions: {
    login (context, user) {
      return new Promise((resolve, reject) => {
        let data = {
          email: user.mobile,
          password: user.password,
          tokenFirebase: user.tokenFirebase,
          tokenOS: user.tokenOS
        }
        axios.defaults.headers.common['set-lang'] = 'he'
        axios.post(window.API.login, data)
          .then(response => {
            let responseData = response.data.success
            context.commit('updateTokens', responseData.loginToken)
            context.commit('updateCurrentUser', responseData.user)
            axios.defaults.headers.common['loginToken'] = `${responseData.loginToken}`
            resolve(response)
          })
          .catch(response => {
            reject(response)
          })
      })
    },
    getCurrentUser (context) {
      return new Promise((resolve, reject) => {
        axios.get(window.API.profile)
          .then(response => {
            let responseData = response.data
            context.commit('updateCurrentUser', responseData)

            resolve(response)
          })
          .catch(response => {
            reject(response)
          })
      })
    },
    changeLang (context, payload, options) {
      return new Promise((resolve, reject) => {
        let data = {
          lang: payload
        }
        axios.defaults.headers.common['set-lang'] = payload
        axios.post(window.API.changeLang, data)
          .then(response => {
            let responseData = response.data
            context.commit('updateCurrentLangDirection', responseData.success.currentLangDirection)
            context.commit('updateCurrentLang', responseData.success.currentLang)
            axios.defaults.headers.common['set-lang'] = `${responseData.success.currentLang}`
            // var LangsSet = { Lang: responseData.success.currentLang, LangDirection: responseData.success.currentLangDirection }
            // window.$cookies.set('CurrentLang', LangsSet)
            window.moment.locale(responseData.success.currentLang)
            resolve(response)
          })
          .catch(response => {
            reject(response)
          })
      })
    },
    PusherAction (context) {
      return new Promise((resolve, reject) => {
        axios.post(window.API.PusherAvailableChannel)
          .then(response => {
            let responseData = response.data.success
            // window.Pusher.logToConsole = true
            const pusher = new window.Pusher('a936e14970e03301e23a', {
              cluster: 'eu',
              forceTLS: true,
              authEndpoint: 'https://api.beesoft.co.il/api/application/pusher/auth',
              auth: {
                headers: {
                  loginToken: `${window.Store.getters.getAccessToken}`
                }
              }
            })
            const availableChannels = responseData.availableChannels
            for (var i = 0; i < availableChannels.length; i++) {
              const studioChannel = pusher.subscribe(availableChannels[i].channel)
              if (availableChannels[i].type === 'beesoft') {
                studioChannel.bind('update', function (data) {
                  alert('בוצע עדכון לאפליקציה: ' + data)
                })
              } else if (availableChannels[i].type === 'studio') {
                studioChannel.bind('update', function (data) {
                  alert('בוצע עדכון בסטודיו: ' + data)
                })
              } else if (availableChannels[i].type === 'user') {
                studioChannel.bind('update', function (data) {
                  // alert('התקבלה הודעת צאט חדשה: ' + data)
                  bus.$emit('ChatNotificationDot')
                  bus.$emit('ChatNotificationMessage', data)
                })
              }
            }
            resolve(response)
          })
          .catch(response => {
            reject(response)
          })
      })
    }
  },

  mutations: {
    updateTokens (state, tokens) {
      state.tokens = tokens
    },
    updateDocs (state, docs) {
      state.docs = []
      state.docs = docs
    },
    updateSource (state, source) {
      state.sourceFull = source
    },
    updateHokFaildReason (state, hokFaildReason) {
      state.hokFaildReason = hokFaildReason
    },
    updateCurrentUser (state, currentUser) {
      state.currentUser = currentUser
    },
    Loader (state, payload) {
      state.overlayLoading = payload
    },
    updateCurrentLangDirection (state, currentLangDirection) {
      state.currentUser.currentLangDirection = currentLangDirection
    },
    updateCurrentLang (state, currentLang) {
      state.currentUser.currentLang = currentLang
    }
  },

  getters: {
    getAccessToken (state) {
      return state.tokens
    },
    getCurrentUser (state) {
      return state.currentUser
    },
    getsourceFull (state) {
      return state.sourceFull
    },
    getDocs (state) {
      return state.docs
    },
    getHokFaildReason (state) {
      return state.hokFaildReason
    },
    getColors (state) {
      return state.colors
    },
    getswatchStyle (state) {
      return state.swatchStyle
    }
  }

})

export default Store
