<template>
  <div class="text-center">
    <v-overlay
      :value="overlayLoading()"
      style="z-index: 999999"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>
  </div>
</template>

<script>
export default {
  methods: {
    overlayLoading () {
      return window.Store.state.overlayLoading
    }
  }
}
</script>
