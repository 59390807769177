'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.default = void 0
var _default = {
  badge: 'Badge',
  close: 'סגור',
  dataIterator: {
    noResultsText: 'לא נמצאו תוצאות מתאימות',
    loadingText: 'טוען... אנא המתן'
  },
  dataTable: {
    itemsPerPageText: 'שורות לעמוד:',
    ariaLabel: {
      sortDescending: ': ממוין לפי סדר עולה. לחץ להספקת המיון.',
      sortAscending: ': ממוין לפי סדר יורד. לחץ למיון לפי סדר עולה.',
      sortNone: ': לא ממוין. לחץ למיון לפי סדר עולה.'
    },
    sortBy: 'סדר לפי'
  },
  dataFooter: {
    itemsPerPageText: 'פריטים לדף:',
    itemsPerPageAll: 'הכל',
    nextPage: 'עמוד הבא',
    prevPage: 'עמוד הקודם',
    firstPage: 'עמוד ראשון',
    lastPage: 'עמוד אחרון',
    pageText: '{0}-{1} מתוך {2}'
  },
  datePicker: {
    itemsSelected: '{0} נבחרו'
  },
  noDataText: 'אין נתונים זמינים',
  carousel: {
    prev: 'מצג קודם',
    next: 'מצג הבא',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} נוספים'
  },
  fileInput: {
    counter: '{0} קבצים',
    counterSize: '{0} קבצים ({1} בסך הכל)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  loginScreen: {
    loginTitle: 'התחברות למערכת',
    loginUserName: 'שם משתמש',
    loginPassword: 'סיסמה',
    lgoinForgotPassword: 'שכחתי סיסמה',
    loginBtn: 'התחבר'
  }
}
exports.default = _default
