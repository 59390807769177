import Vue from 'vue'
import Vuetify from 'vuetify'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
// import en from './i18n/vuetify/en'
import he from './i18n/vuetify/he'
import VueCookies from 'vue-cookies'
import VTooltip from 'v-tooltip'
import '../files/tooltip.scss'
import VueChatScroll from 'vue-chat-scroll'
import VueSignaturePad from 'vue-signature-pad'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { VideoBackground, Plugin } from 'vue-responsive-video-background-player'
import 'swiper/css/swiper.css'
import 'hover.css'

Vue.use(Vuetify)
Vue.use(VueCookies)
Vue.use(VTooltip)
Vue.use(VueChatScroll)
Vue.use(VueSignaturePad)
Vue.use(vueVimeoPlayer)
Vue.use(VueAwesomeSwiper)
Vue.component('video-background', VideoBackground)
Vue.use(Plugin)

VTooltip.options.defaultTrigger = 'hover focus click'

VueCookies.config('1y')
if (VueCookies.isKey('CurrentLang') === false) {
  var LangsSet = { Lang: 'he', LangDirection: true }
  VueCookies.set('CurrentLang', LangsSet)
}

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  lang: {
    locales: { he },
    current: 'he'
  },
  theme: {
    themes: {
      light: {
        // primary: '#111D5E'
      }
    }
  }
})
